import { useEffect, useState } from "react";
import useClient from "./useClient";
import { ClientConfig } from "./client";

const useFetch = <T>(
  relativeUrl: string,
  disabled?: boolean,
  { body, queryParams, config }: ClientConfig = {}
) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const client = useClient();

  useEffect(() => {
    if (disabled) return;

    setIsLoading(true);
    client<T>(relativeUrl, {
      body,
      queryParams,
      config,
    })
      .then((data) => {
        setData(data);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, [body, config, disabled, queryParams, relativeUrl, client]);

  return [data, isLoading, isError] as const;
};

export default useFetch;
