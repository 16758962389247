import { useRef } from "react";
import client, { ClientConfig } from "./client";

const useClient = () => {
  const clientRef = useRef(
    <T>(relativeUrl: string, config: ClientConfig = {}) => {
      return client<T>(`${relativeUrl}`, config).then((result) => {
        return result;
      });
    }
  );

  return clientRef.current;
};

export default useClient;
