import "./App.css";
import {useEffect, useState} from "react";
import useFetch from "./useFetch";
import useInterval from "./useInterval";

function App() {
	const apiUrl = `http://localhost:8081/foobar/receive`;
	const [response, setResponse] = useState("");
	const [disabled, setDisabled] = useState(false);

	const [data] = useFetch(apiUrl, disabled);

	useEffect(() => {
		setResponse(data as string);
		setDisabled(true);
	}, [data]);

	useInterval(3000, () => {
		setDisabled(false);
	});

	console.log(response);

	return (
		<div className="App">
			<header className="App-header">
				<p>
					{response ? (
						JSON.stringify(response)
					) : (
						<>nothing received from {apiUrl} </>
					)}
				</p>
			</header>
		</div>
	);
}

export default App;
