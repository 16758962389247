export type ClientConfig = {
  body?: any;
  method?: string;
  config?: Omit<RequestInit, "body" | "method">;
  queryParams?: Record<string, string | string[] | undefined>;
};

/**
 * only use this function directly if you are doing unauthenticated request
 * otherwise use useClient or useFetch
 * @param endpoint
 * @param options
 */
const client = <T>(
  endpoint: string,
  { body, queryParams, config, method = "GET" }: ClientConfig = {}
): Promise<T> => {
  const isFormData = body instanceof FormData;

  const defaultHeaders = isFormData
    ? undefined
    : {
        "Content-Type": "application/json",
      };

  const customConfig: RequestInit = {
    method: method,
    ...config,
    headers: {
      ...defaultHeaders,
      ...config?.headers,
    },
  };

  if (body) {
    if (body instanceof FormData) {
      customConfig.body = body;
    } else {
      customConfig.body = JSON.stringify(body);
    }
  }

  let query = "";

  if (queryParams && Object.keys(queryParams).length > 0) {
    const queryAdditions: string[] = [];

    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];

      if (!value) return;

      if (Array.isArray(value)) {
        value.forEach((val) => queryAdditions.push(`${key}=${val}`));
      } else {
        queryAdditions.push(`${key}=${value}`);
      }

      query = "?" + queryAdditions.join("&");
    });
  }

  return fetch(endpoint + query, customConfig).then(async (response) => {
    let data = null;
    try {
      data = await response.json();
    } catch (e) {
      //also throws if nothing is returned, eg login
    }

    if (response.ok) {
      return data;
    }

    return Promise.reject(data);
  });
};

export default client;
