import { useEffect } from "react";

/**
 * Hook that run or call a function after specific interval of time
 * @param miliSeconds interval time in miliseconds
 * @param genericFn function that it is called in every interval
 */
const useInterval = (miliSeconds: number, genericFn: () => void) => {
  useEffect(() => {
    const timer = setInterval(() => {
      genericFn();
    }, miliSeconds);

    return () => clearInterval(timer);
  }, [genericFn, miliSeconds]);
};

export default useInterval;
